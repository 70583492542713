import cart1 from './img/Frame-1000005807.jpg';
import cart2 from './img/Frame-1000005808.jpg';
import cart3 from './img/Frame-1000005809.jpg';
import cart4 from './img/Frame-1000005810.jpg';
import cart5 from './img/Frame-1000005811.jpg';
import cart6 from './img/Frame-1000005812.jpg';
import cart7 from './img/Frame-1000005813.jpg';
import cart8 from './img/Frame-1000005814.jpg';
import cart9 from './img/Frame-1000005815.jpg';
import cart10 from './img/Frame-1000005816.jpg';
import cart11 from './img/Frame-1000005817.jpg';
import cart12 from './img/Frame-1000005818.jpg';
import cart13 from './img/Frame-1000005819.jpg';
import cart14 from './img/Frame-1000005820.jpg';
import cart15 from './img/Frame-1000005821.jpg';
import cart16 from './img/Frame-1000005822.jpg';
import cart17 from './img/Frame-1000005823.jpg';
import cart18 from './img/Frame-1000005824.jpg';
import cart19 from './img/Frame-1000005825.jpg';
import cart20 from './img/Frame-1000005826.jpg';
import cart21 from './img/Frame-1000005827.jpg';
import cart22 from './img/Frame-1000005828.jpg';
import cart23 from './img/Frame-1000005829.jpg';
import cart24 from './img/Frame-1000005830.jpg';
import cart25 from './img/Frame-1000005831.jpg';
import cart26 from './img/Frame-1000005832.jpg';
import cart27 from './img/Frame-1000005833.jpg';
import cart28 from './img/Frame-1000005834.jpg';
import cart29 from './img/Frame-1000005835.jpg';
const portfolioArrey = [
  {
    id: '1',
    projectFotoUrl: cart1,
    projectUrl: 'https://www.behance.net/gallery/147986895/GS4B',
    projectYear: '2023',
    projectName: 'WEB DESIGN AGENCY',
    projectServises: 'design, UX/UI',
    projectNameUa: 'WEB DESIGN AGENCY',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '2',
    projectFotoUrl: cart2,
    projectUrl: 'https://dribbble.com/shots/14111494-English-course',
    projectYear: '2023',
    projectName: 'DASHBOARD FOR CRM',
    projectServises: 'design, UX/UI',
    projectNameUa: 'DASHBOARD FOR CRM',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '3',
    projectFotoUrl: cart3,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'LOGISTICS',
    projectServises: 'design, UX/UI',
    projectNameUa: 'LOGISTICS',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '27',
    projectFotoUrl: cart27,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'WEB STUDIO',
    projectServises: 'design, UX/UI',
    projectNameUa: 'WEB STUDIO',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '28',
    projectFotoUrl: cart28,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'FINANCE APP',
    projectServises: 'design, UX/UI',
    projectNameUa: 'FINANCE APP',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '4',
    projectFotoUrl: cart4,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'ELYS',
    projectServises: 'design, UX/UI',
    projectNameUa: 'ELYS',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '5',
    projectFotoUrl: cart5,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'ROYAL CLUB',
    projectServises: 'design, UX/UI',
    projectNameUa: 'ROYAL CLUB',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '6',
    projectFotoUrl: cart6,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'TRAPP',
    projectServises: 'design, UX/UI',
    projectNameUa: 'TRAPP',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '7',
    projectFotoUrl: cart7,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'PAMELA LEARNING CENTRE',
    projectServises: 'design, UX/UI',
    projectNameUa: 'PAMELA LEARNING CENTRE',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '8',
    projectFotoUrl: cart8,
    projectUrl: 'https://www.behance.net/gallery/110724883/VITUS-online-store',
    projectYear: '2023',
    projectName: 'VITUS',
    projectServises: 'design, UX/UI',
    projectNameUa: 'VITUS',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '9',
    projectFotoUrl: cart9,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'PASTEL',
    projectServises: 'design, UX/UI',
    projectNameUa: 'PASTEL',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '10',
    projectFotoUrl: cart10,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'UNLOCK YOUR PHONE',
    projectServises: 'design, UX/UI',
    projectNameUa: 'UNLOCK YOUR PHONE',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '11',
    projectFotoUrl: cart11,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'ZINA LOGISTICS LTD',
    projectServises: 'design, UX/UI',
    projectNameUa: 'ZINA LOGISTICS LTD',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '12',
    projectFotoUrl: cart12,
    projectUrl:
      'https://dribbble.com/shots/21877364-Landing-page-for-ICS-systems-store',
    projectYear: '2023',
    projectName: 'HIGHER PERFOMANCE',
    projectServises: 'design, UX/UI',
    projectNameUa: 'HIGHER PERFOMANCE',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '13',
    projectFotoUrl: cart13,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'BAKERS KITCHEN',
    projectServises: 'design, UX/UI',
    projectNameUa: 'BAKERS KITCHEN',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '14',
    projectFotoUrl: cart14,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'LOGISTICS',
    projectServises: 'design, UX/UI',
    projectNameUa: 'LOGISTICS',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '15',
    projectFotoUrl: cart15,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'MOODBOARD',
    projectServises: 'design, UX/UI',
    projectNameUa: 'MOODBOARD',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '16',
    projectFotoUrl: cart16,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'MOODBOARD',
    projectServises: 'design, UX/UI',
    projectNameUa: 'MOODBOARD',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '17',
    projectFotoUrl: cart17,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'СODESQUAD',
    projectServises: 'design, UX/UI',
    projectNameUa: 'СODESQUAD',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '18',
    projectFotoUrl: cart18,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'FOOD LOVERS',
    projectServises: 'design, UX/UI',
    projectNameUa: 'FOOD LOVERS',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '19',
    projectFotoUrl: cart19,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'YOUNG FOOTBALL CLUB',
    projectServises: 'design, UX/UI',
    projectNameUa: 'YOUNG FOOTBALL CLUB',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '20',
    projectFotoUrl: cart20,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'PALATINO',
    projectServises: 'design, UX/UI',
    projectNameUa: 'PALATINO',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '21',
    projectFotoUrl: cart21,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'MEDICINE APP',
    projectServises: 'design, UX/UI',
    projectNameUa: 'MEDICINE APP',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '22',
    projectFotoUrl: cart22,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'ROYAL GAME',
    projectServises: 'design, UX/UI',
    projectNameUa: 'ROYAL GAME',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '23',
    projectFotoUrl: cart23,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'IGAMING',
    projectServises: 'design, UX/UI',
    projectNameUa: 'IGAMING',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '24',
    projectFotoUrl: cart24,
    projectUrl: 'https://www.behance.net/gallery/142778089/AID-media-tech',
    projectYear: '2023',
    projectName: 'CRM AID MEDIA',
    projectServises: 'design, UX/UI',
    projectNameUa: 'CRM AID MEDIA',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '25',
    projectFotoUrl: cart25,
    projectUrl:
      'https://www.behance.net/gallery/115302775/Digital-studio-The-42-',
    projectYear: '2023',
    projectName: 'THE 42 WEB SITE',
    projectServises: 'design, UX/UI',
    projectNameUa: 'THE 42 WEB SITE',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
  {
    id: '26',
    projectFotoUrl: cart26,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'OWL CHAT',
    projectServises: 'design, UX/UI',
    projectNameUa: 'OWL CHAT',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },

  {
    id: '29',
    projectFotoUrl: cart29,
    projectUrl: 'https://dribbble.com/klimevtushenko',
    projectYear: '2023',
    projectName: 'VIBESTYLE',
    projectServises: 'design, UX/UI',
    projectNameUa: 'VIBESTYLE',
    projectServisesUa: 'design, UX/UI',
    projectServiseTitle: 'services we provided',
    projectServiseTitleUa: 'надані нами послуги',
  },
];
export default portfolioArrey;
